import React from 'react';

import { createRoot } from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import AppProvider from './AppProvider';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <AppProvider>
        <App />
      </AppProvider>
    </React.StrictMode>
  );
}
