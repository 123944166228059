import React, { Suspense } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ApiClientProvider, PublicApiClientProvider } from 'api/apiClient';
import { AuthProvider } from 'contexts/AuthContext';
import GlobalStyle from 'ui/theme/GlobalStyle';

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ApiClientProvider>
      <PublicApiClientProvider>
        <ChakraProvider>
          <Suspense>
            <GlobalStyle />
            <BrowserRouter>
              <AuthProvider>
                {children}
                <ToastContainer
                  newestOnTop
                  hideProgressBar
                  pauseOnHover
                  pauseOnFocusLoss
                  closeOnClick={false}
                  autoClose={3000}
                  position='bottom-left'
                  closeButton
                  theme='colored'
                  icon={false}
                />
              </AuthProvider>
            </BrowserRouter>
          </Suspense>
        </ChakraProvider>
      </PublicApiClientProvider>
    </ApiClientProvider>
  );
};

export default AppProvider;
