import { QueryKey, useQuery } from 'react-query';

function useFetch<TResult>(
  queryKey: QueryKey,
  call: () => Promise<TResult>,
  options?: UseFetchOptions<TResult>
): UseFetchResult<TResult> {
  return useQuery(queryKey, call, options);
}

export default useFetch;
