import React, { lazy } from 'react';

import { Route, Routes } from 'react-router-dom';
import 'ress';

import Protected from 'ui/utils/Protected';

const AdminUserManagePage = lazy(
  () => import('components/pages/AdminUserManagePage')
);
const ArticleCategoryMasterPage = lazy(
  () => import('components/pages/ArticleCategoryMasterPage')
);
const ArticleCreatePage = lazy(
  () => import('components/pages/ArticleCreatePage')
);
const ArticleEditPage = lazy(() => import('components/pages/ArticleEditPage'));
const ArticleManagePage = lazy(
  () => import('components/pages/ArticleManagePage')
);
const ArticleTagMasterPage = lazy(
  () => import('components/pages/ArticleTagMasterPage')
);
const CarCreatePage = lazy(() => import('components/pages/CarCreatePage'));
const CarEditPage = lazy(() => import('components/pages/CarEditPage'));
const CarBasicFacilityMasterPage = lazy(
  () => import('components/pages/CarBasicFacilityMasterPage')
);
const CarAdditionalFacilityMasterPage = lazy(
  () => import('components/pages/CarAdditionalFacilityMasterPage')
);
const CarInsurePriceMasterPage = lazy(
  () => import('components/pages/CarInsurePriceMasterPage')
);
const CarKindMasterPage = lazy(
  () => import('components/pages/CarKindMasterPage')
);
const CarManagePage = lazy(() => import('components/pages/CarManagePage'));
const CarMasterPage = lazy(() => import('components/pages/CarMasterPage'));
const CarRuleMasterPage = lazy(
  () => import('components/pages/CarRuleMasterPage')
);
const DashboardPage = lazy(() => import('components/pages/DashboardPage'));
const NewsCreatePage = lazy(
  () => import('components/pages/NewsCreatePage/NewsCreatePageContainer')
);
const NewsEditPage = lazy(
  () => import('components/pages/NewsEditPage/NewsEditPageContainer')
);
const NewsManagePage = lazy(
  () => import('components/pages/NewsManagePage/NewsManagePageContainer')
);
const OwnerInterviewCreatePage = lazy(
  () =>
    import(
      'components/pages/OwnerInterviewCreatePage/OwnerInterviewCreatePageContainer'
    )
);
const OwnerInterviewEditPage = lazy(
  () =>
    import(
      'components/pages/OwnerInterviewEditPage/OwnerInterviewEditPageContainer'
    )
);
const OwnerInterviewManagePage = lazy(
  () =>
    import(
      'components/pages/OwnerInterviewManagePage/OwnerInterviewManagePageContainer'
    )
);
const LoginPage = lazy(() => import('components/pages/LoginPage'));
const MastersPage = lazy(() => import('components/pages/MastersPage'));
const NotFoundPage = lazy(() => import('components/pages/NotFoundPage'));
const PaymentManagePage = lazy(
  () => import('components/pages/PaymentManagePage')
);
const ReserveManagePage = lazy(
  () => import('components/pages/ReserveManagePage')
);
const SpotBasicFacilityMasterPage = lazy(
  () => import('components/pages/SpotBasicFacilityMasterPage')
);
const SpotAdditionalFacilityMasterPage = lazy(
  () => import('components/pages/SpotAdditionalFacilityMasterPage')
);
const SpotPlanMasterPage = lazy(
  () => import('components/pages/SpotPlanMasterPage')
);
const SpotRuleMasterPage = lazy(
  () => import('components/pages/SpotRuleMasterPage')
);
const SpotTypeMasterPage = lazy(
  () => import('components/pages/SpotTypeMasterPage')
);
const SpotSurroundingFacilityMasterPage = lazy(
  () => import('components/pages/SpotSurroundingFacilityMasterPage')
);
const SpotCreatePage = lazy(() => import('components/pages/SpotCreatePage'));
const SpotManagePage = lazy(() => import('components/pages/SpotManagePage'));
const SpotEditPage = lazy(() => import('components/pages/SpotEditPage'));
const UserCreatePage = lazy(() => import('components/pages/UserCreatePage'));
const UserEditPage = lazy(() => import('components/pages/UserEditPage'));
const UserManagePage = lazy(() => import('components/pages/UserManagePage'));
const WithMenuLayout = lazy(
  () => import('components/templates/WithMenuLayout')
);
const TermsSettingPage = lazy(
  () => import('components/pages/TermsSettingPage')
);
const PrivacyPolicySettingPage = lazy(
  () => import('components/pages/PrivacyPolicySettingPage')
);
const ShareContractSettingPage = lazy(
  () => import('components/pages/ShareContractSettingPage')
);
const FaqManagePage = lazy(() => import('components/pages/FaqManagePage'));
const FaqCreatePage = lazy(() => import('components/pages/FaqCreatePage'));
const FaqEditPage = lazy(() => import('components/pages/FaqEditPage'));
const CarLongTermDiscountMasterPage = lazy(
  () => import('components/pages/CarLongTermDiscountMasterPage')
);
const CarFees = lazy(() => import('components/pages/CarFeeMaster'));

const App: React.FC = () => (
  <Routes>
    <Route path='' element={<LoginPage />} />
    <Route path='protected' element={<Protected />}>
      <Route path='' element={<WithMenuLayout />}>
        <Route path='' element={<DashboardPage />} />
        <Route path='adminUsers' element={<AdminUserManagePage />} />
        <Route path='articles'>
          <Route path='' element={<ArticleManagePage />} />
          <Route path='create' element={<ArticleCreatePage />} />
          <Route path='edit/:articleId' element={<ArticleEditPage />} />
        </Route>
        {/* masters */}
        <Route path='masters'>
          <Route path='' element={<MastersPage />} />
          <Route
            path='articleCategories'
            element={<ArticleCategoryMasterPage />}
          />
          <Route path='articleTags' element={<ArticleTagMasterPage />} />
          <Route
            path='carBasicFacilities'
            element={<CarBasicFacilityMasterPage />}
          />
          <Route
            path='carAdditionalFacilities'
            element={<CarAdditionalFacilityMasterPage />}
          />
          <Route path='carKind' element={<CarKindMasterPage />} />
          <Route path='carInsurePrice' element={<CarInsurePriceMasterPage />} />
          <Route
            path='carLongTermDiscount'
            element={<CarLongTermDiscountMasterPage />}
          />
          <Route path='cars' element={<CarMasterPage />} />
          <Route path='carFees' element={<CarFees />} />
          <Route path='carRules' element={<CarRuleMasterPage />} />
          <Route
            path='spotBasicFacilities'
            element={<SpotBasicFacilityMasterPage />}
          />
          <Route
            path='spotAdditionalFacilities'
            element={<SpotAdditionalFacilityMasterPage />}
          />
          <Route path='spotPlans' element={<SpotPlanMasterPage />} />
          <Route path='spotRules' element={<SpotRuleMasterPage />} />
          <Route path='spotTypes' element={<SpotTypeMasterPage />} />
          <Route
            path='spotSurroundingFacilities'
            element={<SpotSurroundingFacilityMasterPage />}
          />
          <Route path='terms' element={<TermsSettingPage />} />
          <Route path='privacyPolicy' element={<PrivacyPolicySettingPage />} />
          <Route path='shareContract' element={<ShareContractSettingPage />} />
        </Route>
        {/* /masters */}
        <Route path='users'>
          <Route path='' element={<UserManagePage />} />
          <Route path='create' element={<UserCreatePage />} />
          <Route path='edit/:userId' element={<UserEditPage />} />
        </Route>
        <Route path='cars'>
          <Route path='' element={<CarManagePage />} />
          <Route path='create' element={<CarCreatePage />} />
          <Route path='edit/:carId' element={<CarEditPage />} />
        </Route>
        <Route path='spots'>
          <Route path='' element={<SpotManagePage />} />
          <Route path='create' element={<SpotCreatePage />} />
          <Route path='edit/:spotId' element={<SpotEditPage />} />
        </Route>
        <Route path='reserves'>
          <Route path='' element={<ReserveManagePage />} />
        </Route>
        <Route path='news'>
          <Route path='' element={<NewsManagePage />} />
          <Route path='create' element={<NewsCreatePage />} />
          <Route path='edit/:newsId' element={<NewsEditPage />} />
        </Route>
        <Route path='ownerInterviews'>
          <Route path='' element={<OwnerInterviewManagePage />} />
          <Route path='create' element={<OwnerInterviewCreatePage />} />
          <Route path='edit/:id' element={<OwnerInterviewEditPage />} />
        </Route>
        <Route path='faqs'>
          <Route path='' element={<FaqManagePage />} />
          <Route path='create' element={<FaqCreatePage />} />
          <Route path='edit/:faqId' element={<FaqEditPage />} />
        </Route>
        <Route path='payments'>
          <Route path='' element={<PaymentManagePage />} />
        </Route>
      </Route>
    </Route>
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default App;
