import React, { createContext, useContext, useCallback } from 'react';

import * as campers from '@wb-quaero/camper-interface-admin/api';
import { AxiosRequestConfig } from 'axios';
import { MutationCache, QueryClient, QueryClientProvider } from 'react-query';

import token from 'ui/utils/token';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
  mutationCache: new MutationCache(),
});

const campersApiClient = {
  ...campers.AdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.ArticleAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.CarMasterAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.CarAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.UserAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.NewsAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.OwnerInterviewAdminServiceApiFactory(
    undefined,
    __BACKEND_BASE_URL__
  ),
  ...campers.SpotAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.SpotMasterAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.TermsAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.FaqAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.SystemAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.FeeAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
  ...campers.ReserveAdminServiceApiFactory(undefined, __BACKEND_BASE_URL__),
};

type ApiClient = typeof campersApiClient;

export const ApiClientContext = createContext<ApiClient | undefined>(undefined);

export const ApiClientProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ApiClientContext.Provider value={campersApiClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ApiClientContext.Provider>
  );
};

export const useApiClient = <K extends keyof ApiClient>(
  key: K,
  config?: AxiosRequestConfig
) => {
  const apiClient = useContext<ApiClient | undefined>(ApiClientContext);
  if (!apiClient) {
    throw new Error('API client is not set');
  }

  const api = apiClient[key];
  const call = useCallback(
    async (
      payload: Partial<Parameters<ApiClient[typeof key]>[0]>
    ): Promise<Awaited<ReturnType<ApiClient[K]>>['data']> => {
      const authToken = token.getToken();
      if (!token) {
        throw new Error('No token provided');
      }

      return await api(payload as never, {
        ...config,
        headers: {
          ...config?.headers,
          Authorization: `Bearer ${authToken}`,
        },
      }).then((res) => res.data);
    },
    [api, config]
  );

  return call;
};

const camperPublicApiClient = {
  ...campers.AdminPublicServiceApiFactory(undefined, __BACKEND_BASE_URL__),
};

type PublicApiClient = typeof camperPublicApiClient;

export const PublicApiClientContext = createContext<
  PublicApiClient | undefined
>(undefined);

export const PublicApiClientProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <PublicApiClientContext.Provider value={camperPublicApiClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </PublicApiClientContext.Provider>
  );
};

export const usePublicApiClient = <K extends keyof PublicApiClient>(
  key: K,
  config?: AxiosRequestConfig
) => {
  const apiClient = useContext<PublicApiClient | undefined>(
    PublicApiClientContext
  );
  if (!apiClient) {
    throw new Error('API client is not set');
  }

  const api = apiClient[key];
  const call = useCallback(
    async (
      payload: Partial<Parameters<PublicApiClient[typeof key]>[0]>
    ): Promise<Awaited<ReturnType<PublicApiClient[K]>>['data']> => {
      return await api(payload as never, config).then((res) => res.data);
    },
    [api, config]
  );

  return call;
};
