import React from 'react';

import { CircularProgress } from '@chakra-ui/react';
import styled from '@emotion/styled';

type Props = {
  fontSize?: string;
};

const Loading: React.FC<Props> = ({ fontSize }) => (
  <Container>
    <CircularProgress isIndeterminate size={fontSize} />
  </Container>
);

export default Loading;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
