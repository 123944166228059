import { queryClient, usePublicApiClient } from 'api/apiClient';
import useUpdate from 'api/utils/useUpdate';
import toast from 'ui/utils/toast';

export const MUTATION_KEY_LOGOUT = 'adminServiceAdminLogout';

const useLogout: UseUpdate<object, object> = (options) => {
  const api = usePublicApiClient('adminLogout');

  const res = useUpdate(api, {
    mutationKey: MUTATION_KEY_LOGOUT,
    ...options,
    onError: (error, variables, context) => {
      toast.error('ログアウトに失敗しました');

      queryClient.resetQueries();

      options?.onError?.(error, variables, context);
    },
    onSuccess: (data, variables, context) => {
      toast.info('ログアウトしました');

      queryClient.resetQueries();

      options?.onSuccess?.(data, variables, context);
    },
  });

  return res;
};

export default useLogout;
