import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Noto Sans JP', sans-serif;
  }

  html, body {
    min-height: 100vh;
  }

  #root {
    min-height: 100vh;
  }
`;

export default GlobalStyle;
